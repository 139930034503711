import { useState } from 'react';
import { Spinner } from 'bv-components';

import OneAuth from 'SharedComponents/one_auth';

const OneAuthApp = () => {
  const [redirecting, setRedirecting] = useState(false);

  if (redirecting) return <Spinner />;

  return <OneAuth action="login" setRedirecting={setRedirecting} />;
};

export default OneAuthApp;
