import { t } from 'bv-i18n';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

const UserInput = ({ value, onInput, onBlur }) => {
  const loginInputClasses = classnames('bvs-form-control', {
    active: value !== '',
  });

  return (
    <div className="bvs-form-group">
      <input
        type="text"
        name="username"
        id="login-input"
        value={value}
        onInput={(e) => onInput(e.target.value)}
        onBlur={onBlur}
        className={loginInputClasses}
      />
      <label htmlFor="login-input" className="bvs-form-control-placeholder">
        {t('username_or_email_label')}
      </label>
    </div>
  );
};

UserInput.propTypes = {
  value: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

export default UserInput;
