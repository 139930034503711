import { useEffect } from 'react';
import { withModalWindow } from 'bv-hocs';
import { useOneAuth } from 'bv-hooks';
import { v as bvVar } from 'bv';

import SignInUp from 'SharedComponents/sign_in_up';

import Traditional from './flows/traditional';
import OneAuth from './flows/one_auth';

const LoginApp = () => {
  const [oneAuthEnabled, oneAuthLoaded] = useOneAuth();
  const centralisedSignupLogin = bvVar('isCentralisedSignupLogin');

  useEffect(() => {
    document.documentElement.classList.add('login-view-launched');

    return () => {
      document.documentElement.classList.remove('login-view-launched');
    };
  }, []);

  if (!oneAuthLoaded) return null;

  const Component = oneAuthEnabled ? OneAuth : Traditional;

  return (
    <>
      {centralisedSignupLogin && <SignInUp />}
      <Component />
    </>
  );
};

export default withModalWindow(
  'login',
  { className: 'login-modal' },
)(LoginApp);
