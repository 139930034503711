import { useLocation } from 'react-router-dom';
import { Link } from 'bv-components';
import { t } from 'bv-i18n';

const links = [
  {
    to: '/account/new',
    label: t('javascript.sign_in_and_up_tabs.sign_up'),
  },
  {
    to: '/login',
    label: t('javascript.sign_in_and_up_tabs.log_in'),
  },
];

const SignInUpView = () => {
  const { pathname } = useLocation();

  return (
    <div className="sign-in-and-up-tabs">
      {links.map(({ to, label }) => (
        <Link to={to} className={to === pathname ? 'active' : ''}>
          {label}
        </Link>
      ))}
    </div>
  );
};

export default SignInUpView;
