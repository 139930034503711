import { t } from 'bv-i18n';
import { linkLocalizer } from 'bv-helpers/locale';
import { Button } from 'bv-components';

const FooterTonality = () => (
  <div className="login-form-register-wrapper">
    <div className="login-register">
      <span className="login-register__label">{t('login_not_yet_registered')}</span>
      <Button
        href={linkLocalizer('/account/new')}
        label={t('open_account')}
        inverse
        secondary
      />
    </div>
  </div>
);

export default FooterTonality;
