import { isHCaptcha, getCaptchaService } from 'bv-services';
import { replaceFragment, setLocation } from 'bv-helpers/location';
import { resetLookupPromise } from '../services/check_user';

export default (response, setLoading, setError, retry) => {
  const captchaService = getCaptchaService({ allowHCaptcha: true });

  if (response.logout) {
    if (response.message) {
      resetLookupPromise();
      setError({ message: response.message });
      setLoading(false);
    } else {
      setLocation('/logout');
    }
  } else if (response.missing_2fa) {
    if (response.need_location_check) {
      replaceFragment('twofactorauth/login/geo');
    } else {
      replaceFragment('twofactorauth/login');
    }
  } else if (response.retry && captchaService.shouldRetry()) {
    retry(response.retry);
  } else {
    resetLookupPromise();
    setError({
      message: response.message,
      resetButton: response.show_reset_password,
      errorKey: response?.error_key,
    });
    setLoading(false);
  }
  if (isHCaptcha) {
    captchaService.refresh();
  }
};
