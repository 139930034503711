import { getBlackBox, getCaptchaService } from 'bv-services';
import success from '../handlers/login_success';
import userLookup from '../api/user_lookup';

let lookupData = {
  promise: null,
  lastUser: null,
  lastDate: null,
};

export const resetLookupPromise = () => {
  lookupData.promise = null;
};

const checkUser = async (user, hasRetried = false) => {
  if (!user.trim()) {
    return null;
  }

  if (
    lookupData.promise
    && lookupData.lastUser === user.trim()
    && ((new Date() - lookupData.lastDate) / 1000) < 20
  ) {
    const previousResult = await lookupData.promise;
    return previousResult;
  }

  lookupData = {
    lastUser: user.trim(),
    lastDate: new Date(),
  };
  const captchaService = getCaptchaService();

  lookupData.promise = new Promise((resolve) => {
    const loginData = { username: user.trim() };
    Promise.all([captchaService.execute('userLookup'), getBlackBox()]).then(([token, iobb]) => {
      loginData.token = token;
      loginData.iobb = iobb;

      userLookup(loginData).then((resp) => {
        resolve(resp);
      }).catch((resp) => {
        // if lookup fails for csrf or other reasons,
        // return body of bv-fetch api-error exception
        resolve(resp?.body || {});
      });
    });
  }).then((response) => {
    if (response?.success) {
      success(response);
      return null;
    }

    if (response?.tokenExpiredRetry
        || (response?.retry && !hasRetried && captchaService.shouldRetry())
    ) {
      resetLookupPromise();
      return captchaService.refresh().then(() => checkUser(user, true));
    }

    return response;
  }).catch((resp) => {
    lookupData.promise = null;
    return resp?.body || {};
  });
  const lookupResult = await lookupData.promise;
  return lookupResult;
};

export default checkUser;
