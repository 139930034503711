import { PropTypes } from 'prop-types';

const Form = ({
  children, submit, setLoading, loading, submitIsDisabled,
}) => {
  const onSubmit = (e) => {
    e.preventDefault();

    if (!loading && !submitIsDisabled) {
      // is needed for the handling of multiple submit by the enter key
      setLoading(true);
      submit();
    }
  };

  return (
    <form onSubmit={onSubmit} className="login-form bvs-card">
      {children}
    </form>
  );
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  submit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  submitIsDisabled: PropTypes.bool.isRequired,
};

export default Form;
