import { t } from 'bv-i18n';
import { linkLocalizer } from 'bv-helpers/locale';
import { Button } from 'bv-components';

const PasswordResetButton = () => (
  <Button
    href={linkLocalizer('/account/password_reset')}
    label={t('reset_password')}
    type="login-view-password-change-button"
    noClass
  />
);

export default PasswordResetButton;
