import { v as bvVar } from 'bv';

const Header = () => (
  /* eslint-disable jsx-a11y/anchor-has-content */
  <div className="branded-modal__header">
    <a className="branded-modal__title" aria-label="Logo" href={bvVar('rootPath')}>
      <div className="bvs-site-logo__login" />
    </a>
  </div>
);

export default Header;
