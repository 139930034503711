import { setLocation } from 'bv-helpers/location';
import { MINUTE_IN_MILLIS } from 'bv-helpers/datetime';
import postLocationData from '../api/post_location_data';

const revokeReason = {
  MISSING_GEOLOCATION: 'MISSING_GEOLOCATION',
};

const logOutIfNeeded = (logoutNeed = false, reason = null, resp = null) => {
  if (logoutNeed) {
    setLocation(`/logout${reason ? `?reason=${reason}` : ''}`);
    return null;
  }
  return resp;
};

export const getPosition = async (logOutOnReject = true, opts = {}) => {
  const buildPosition = (position) => ({
    latitude: position.coords.latitude,
    longitude: position.coords.longitude,
  });

  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => resolve(buildPosition(position)),
      (resp) => reject(logOutIfNeeded(logOutOnReject, revokeReason.MISSING_GEOLOCATION, resp)),
      opts,
    );
  });
};

const positionPost = async () => {
  const res = await postLocationData();
  if (res?.geopositionNeed) {
    const position = await getPosition(true);
    const response = await postLocationData({ position });
    logOutIfNeeded(response?.logout);
  }
  logOutIfNeeded(res?.logout);
};

export const locationCheckTimer = () => {
  positionPost();
  setInterval(positionPost, 5 * MINUTE_IN_MILLIS);
};
