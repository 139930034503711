import { getBlackBox, getCaptchaService } from 'bv-services';
import { set } from 'bv-local-storage';
import login from '../api/login';
import success from '../handlers/login_success';
import failure from '../handlers/login_failure';
import error from '../handlers/login_error';
import userLookup from '../api/user_lookup';
import { getPosition } from './location_check_timer';
/* eslint-disable no-param-reassign */

const loginService = async (
  loginData, setLoading, setError, hasRetried = false,
) => {
  const shouldLogin = true;

  const retry = async (captcha) => {
    if (!hasRetried) {
      if (captcha) {
        loginData.token = await getCaptchaService({ allowHCaptcha: true }).refresh();
      }
      loginService(
        loginData, setLoading, setError, true,
      );
    }
  };

  const performLogin = (data) => {
    login(data)
      .then((res) => {
        if (res.needLocationCheck) {
          set('need_location_check', 1);
        }

        if (res.geopositionNeed) {
          return getPosition(false)
            .then((position) => {
              data.authenticationId = res.authenticationId;
              data.position = position;
              return userLookup({
                iobb: null,
                username: data.username,
              })
                .then(() => performLogin(data));
            })
            .catch(() => {
              failure(res, setLoading, setError, retry);
            });
        }

        return res.success
          ? success(res)
          : failure(res, setLoading, setError, retry);
      })
      .catch(() => error(setLoading, setError));
  };

  loginData.iobb = await getBlackBox();

  if (shouldLogin) {
    await performLogin(loginData);
  }
};

export default loginService;
