import { t } from 'bv-i18n';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';

const FitToPlayInput = ({
  confirmedFitToPlay,
  fitToPlayError,
  setConfirmedFitToPlay,
  setFitToPlayError,
}) => {
  const labelClasses = classnames('bvs-form-control-placeholder', {
    error: fitToPlayError,
  });

  const toggleCheckbox = () => {
    setConfirmedFitToPlay(!confirmedFitToPlay);
    if (fitToPlayError) setFitToPlayError(false);
  };

  return (
    <div className="bvs-form-group bvs-fit-to-play_checkbox">
      <input
        type="checkbox"
        name="fit_to_play"
        id="fit-to-play-input"
        checked={confirmedFitToPlay}
        onChange={toggleCheckbox}
        className={fitToPlayError ? 'error' : ''}
      />
      <label htmlFor="fit-to-play-input" className={labelClasses}>
        {t('confirm_fit_to_play')}
      </label>
    </div>
  );
};

FitToPlayInput.propTypes = {
  confirmedFitToPlay: PropTypes.bool.isRequired,
  fitToPlayError: PropTypes.bool.isRequired,
  setConfirmedFitToPlay: PropTypes.func.isRequired,
  setFitToPlayError: PropTypes.func.isRequired,
};

export default FitToPlayInput;
