import { useState, useEffect } from 'react';
import { throttle } from 'underscore';
import { Spinner } from 'bv-components';
import { destroyCaptchaServices, isHCaptcha, loadCaptchaServices } from 'bv-services';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { v as bvVar } from 'bv';

import HCaptcha from 'SharedComponents/hcaptcha';
import { selectHCaptchaCompleted } from 'SharedComponents/hcaptcha/selectors';
import SubmitButton from '../components/submit_button';
import Form from '../components/form';
import UserInput from '../components/user_input';
import PasswordInput from '../components/password_input';
import PasswordResetButton from '../components/password_reset_button';
import Message from '../components/message';
import Header from '../components/header';
import FooterTonality from '../components/footer_tonality';
import FitToPlayInput from '../components/fit_to_play_input';

import submitService from '../services/submit';
import checkUser from '../services/check_user';
import validate from '../services/validate';

const throttledCheckUser = throttle(checkUser, 5000);

// Named export for testing. The component should not be directly written in the index
// Modules to export: View, Container, ModalWindow
const Traditional = () => {
  const email = localStorage.getItem('email_in_use');
  const [user, setUser] = useState(email || '');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [captchaLoading, setCaptchaLoading] = useState(true);
  const hCaptchaCompleted = useSelector(selectHCaptchaCompleted);

  const confirmFitToPlayOnLogin = bvVar('confirmFitToPlayOnLogin');
  const [confirmedFitToPlay, setConfirmedFitToPlay] = useState(false);
  const [fitToPlayError, setFitToPlayError] = useState(false);

  useEffect(() => {
    if (email) {
      localStorage.removeItem('email_in_use');
    }
  }, []);

  useEffect(() => {
    loadCaptchaServices().then(() => {
      setCaptchaLoading(false);
    });

    return destroyCaptchaServices;
  }, []);

  const closeMessage = () => setError(null);
  const submitIsDisabled = !validate({ username: user, password }).valid
    || (isHCaptcha && !hCaptchaCompleted) || (confirmFitToPlayOnLogin && fitToPlayError);
  const onSubmit = () => {
    if (confirmFitToPlayOnLogin && !confirmedFitToPlay) {
      setFitToPlayError(true);
      setLoading(false);
    } else if (!loading) {
      submitService(
        user,
        password,
        setLoading,
        setError,
        loading,
      );
    }
  };

  const { from } = useLocation().state || {};

  if (from) {
    // Rather than using this global variable, we should pass this to the submit service
    // Too much pass of params, we'll work on bringing the handlers to this component
    // eslint-disable-next-line no-undef
    VC.afterLoginRedirect = `${from.pathname}${from.search}`;
  }

  return captchaLoading ? (
    <Spinner />
  ) : (
    <div className="login-content-wrapper">
      <Header />
      <Form
        submit={onSubmit}
        setLoading={setLoading}
        loading={loading}
        submitIsDisabled={submitIsDisabled}
      >
        <UserInput
          value={user}
          onInput={setUser}
          onBlur={() => throttledCheckUser(user)}
        />
        <PasswordInput value={password} onInput={setPassword} />
        {confirmFitToPlayOnLogin && (
          <FitToPlayInput
            confirmedFitToPlay={confirmedFitToPlay}
            setConfirmedFitToPlay={setConfirmedFitToPlay}
            fitToPlayError={fitToPlayError}
            setFitToPlayError={setFitToPlayError}
          />
        )}

        <HCaptcha />
        <div className="login-password-change-wrapper">
          <PasswordResetButton />
        </div>
        <SubmitButton disabled={submitIsDisabled} loading={loading} />
      </Form>
      <FooterTonality />
      {error && <Message error={error} close={closeMessage} />}
    </div>
  );
};

export default Traditional;
