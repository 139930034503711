import { useState, useRef } from 'react';
import { t } from 'bv-i18n';
import { PropTypes } from 'prop-types';
import { Icon } from 'bv-components';
import classnames from 'classnames';

const PasswordInput = ({ value, onInput }) => {
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);

  const setValue = () => {
    const newVal = inputRef.current.value;
    inputRef.current.value = newVal;
  };

  const passwordInputClasses = classnames('bvs-form-control', {
    active: value !== '',
  });

  return (
    <div className="bvs-form-group">
      <input
        ref={inputRef}
        type={showPassword ? 'text' : 'password'}
        name="password"
        id="current-password"
        value={value}
        autoComplete="off"
        onInput={(e) => onInput(e.target.value)}
        className={passwordInputClasses}
      />
      <label htmlFor="current-password" className="bvs-form-control-placeholder">
        {t('password')}
      </label>
      <Icon
        id={`eye-catcher-${showPassword ? 'active' : 'inactive'}`}
        onClick={() => { setShowPassword(!showPassword); setValue(); }}
        className="account-form__eye-catcher-icon"
      />
    </div>
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onInput: PropTypes.func.isRequired,
};

export default PasswordInput;
