import { PropTypes } from 'prop-types';
import { sanitize } from 'dompurify';
import { Modal } from 'bv-components';
import { setFragment } from 'bv-helpers/location';
import { t } from 'bv-i18n';

const goToResetPage = () => setFragment('account/password_reset');

const Message = ({ error, close }) => (
  <Modal
    title={t('cannot_log_in')}
    danger
    iconClosable
    icon={false}
    onCloseClick={close}
    actions={error.resetButton
    && [{ label: t('reset_your_password'), danger: true, onClick: goToResetPage }]}
    dataLayer={{
      event: 'evt_dialog_danger',
      error_title: t('cannot_log_in'),
      error_message: sanitize(error.message),
      error_key: error.errorKey,
      error_code: 'E0x014',
    }}
  >
    <p dangerouslySetInnerHTML={{ __html: sanitize(error.message) }} />
  </Modal>
);

Message.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
    resetButton: PropTypes.bool,
    errorKey: PropTypes.string,
  }),
  close: PropTypes.func.isRequired,
};

Message.defaultProps = {
  error: {
    message: t('something_went_wrong_try_again'),
    errorKey: 'something_went_wrong_try_again',
  },
};

export default Message;
