import { isInvisibleHCaptcha, getCaptchaService } from 'bv-services';
import checkUser from './check_user';
import loginService from './login_service';
import validate from './validate';

const onSubmit = async (user, password, setLoading, setError) => {
  const loginData = { username: user, password };

  const result = validate(loginData);
  if (!result.valid) {
    setError(result.message);
    setLoading(false);
    return;
  }

  const lookupResponse = await checkUser(user);
  if (lookupResponse.error) {
    setError(lookupResponse.error);
    setLoading(false);
    return;
  }

  loginData.token = await getCaptchaService({ allowHCaptcha: true }).execute('login');
  loginData.authenticationId = lookupResponse.authenticationId;

  if (isInvisibleHCaptcha && !loginData.token) { // challenge was dismissed
    setLoading(false);
  } else {
    await loginService(loginData, setLoading, setError);
  }
};

export default onSubmit;
