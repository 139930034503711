import { t } from 'bv-i18n';
import { PropTypes } from 'prop-types';
import { Button } from 'bv-components';

const SubmitButton = ({ disabled, loading }) => (
  <Button
    name="login_submit"
    disabled={loading || disabled}
    type="submit"
    primary
    label={t('login')}
    loading={loading}
  />
);

SubmitButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SubmitButton;
