import { t } from 'bv-i18n';

export default ({ username, password }) => {
  const MAX_USERNAME_LENGTH = 100;

  if (!username.trim()) {
    return {
      valid: false,
      message: t('twofa.error.empty_username'),
    };
  }

  if (username.length > MAX_USERNAME_LENGTH) {
    return {
      valid: false,
      message: t('twofa.error.long_username', { max_characters: MAX_USERNAME_LENGTH }),
    };
  }

  if (!password.trim()) {
    return {
      valid: false,
      message: t('twofa.error.empty_password'),
    };
  }

  return { valid: true };
};
